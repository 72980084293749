import { Alert, Button, CircularProgress, Typography } from '@mui/material';
import styles from '../styleLoginWithPhone.module.scss';
import CustomCodeInput from '../../customCodeInput/CustomCodeInput';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmCode,
  resetCountCodeResend,
  selectAuthPhoneNumber,
  selectConfirmError,
  selectConfirmLoading,
  selectCountCodeResend,
  selectIsCodeSent,
  selectTimeResend,
  sendCode,
  setAuthPhoneNumber,
  setConfirmError,
  setConfirmLoading,
  setCountCodeResend,
  setIsCodeSent,
  setTimeResend,
} from '../../../store/authCodeSlice';
import { getFormattedPhone } from '../../../helperFunctions/phone.help';
import useAuth from '../../../hooks/useAuth';
import { TEMP_USER_KEY } from '../../../consts/local_keys';
import { setIsOpenMobleAuth } from '../../../store/mainSlice';
import CustomTimer from '../../customTimer/CustomTimer';
const CodeStep = ({ overridedOnConfirm, afterSubmit }) => {
  const confirmLoading = useSelector(selectConfirmLoading);
  const confirmError = useSelector(selectConfirmError);
  const countCodeResend = useSelector(selectCountCodeResend)
  const { setAuth } = useAuth();
  const { handleSubmit, getValues, watch, reset } = useFormContext();
  const dispatch = useDispatch();
  // const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(false);
  const isResendCodeDisabled = countCodeResend === 0
  const isCodeSent = useSelector(selectIsCodeSent);
  const timeResend = useSelector(selectTimeResend);
  const statePhone = useSelector(selectAuthPhoneNumber);
  const watchPhone = watch('phoneNumber');
  useEffect(() => {
    const currentPhone = `+${getFormattedPhone(watchPhone)}`;
    if (statePhone !== currentPhone && isCodeSent) {
      // setIsResendCodeDisabled(false);
      dispatch(resetCountCodeResend())
    }
  }, [watchPhone]);
  const resendCode = () => {
    const phone = getValues('phoneNumber');
    if (phone)
      dispatch(sendCode(getFormattedPhone(phone))).then(() =>
        // setIsResendCodeDisabled(true)
      countCodeResend > 0 && dispatch(setCountCodeResend(countCodeResend - 1))
      );
    return;
  };

  const resetAuth = () => {
    dispatch(setIsOpenMobleAuth(false));
    dispatch(setIsCodeSent(false));
    dispatch(setAuthPhoneNumber(''));
    reset();
  };

  const onConfirm = (e) => {
    e.preventDefault();
    if (overridedOnConfirm) {
      overridedOnConfirm();
      return;
    }
    handleSubmit((data) => {
      dispatch(setConfirmLoading(true));
      const payload = {
        authCode: data.authCode,
        phoneNumber: getFormattedPhone(data.phoneNumber),
      };
      dispatch(confirmCode(payload)).then(({ payload }) => {
        if (!payload) return;
        const { data } = payload;
        const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
        const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
        const roles = [data?.userDto.userType];
        const user = JSON.stringify(data?.userDto);
        if (accessToken && refreshToken && roles && user) {
          setAuth({ accessToken, refreshToken, user, roles });
        }
        resetAuth();
        localStorage.removeItem(TEMP_USER_KEY);
        if (afterSubmit) afterSubmit();
      });
    })();
  };
  const authCode = watch('authCode');

  useEffect(() => {
    if (confirmError) {
      dispatch(setConfirmError(false));
    }
  }, [authCode]);
  return (
    <>
      <Typography className={styles.description}>
        נשלח קוד בן ארבע ספרות למספר הטלפון שהזנת אצלנו. אנא הזן את הקוד על מנת
        להמשיך
      </Typography>
      <CustomCodeInput
        isInvalidCode={confirmError}
        name="authCode"
        sx={{ marginTop: '9px' }}
      />
      <Typography
        onClick={() => {
          if (!isResendCodeDisabled) resendCode();
        }}
        className={classNames(
          styles.text_helper,
          isResendCodeDisabled || timeResend > 0
            ? styles.text_helper_disable
            : styles.text_helper_active
        )}
      >
        {isResendCodeDisabled || timeResend > 0
          ? 'הקוד נשלח'
          : 'לא קיבלתי קוד, שלחו שוב'}
      </Typography>
      {timeResend > 0 && (
        <CustomTimer
          seconds={timeResend}
          setSeconds={(second) => {
            dispatch(setTimeResend(second));
          }}
        />
      )}
      <Button
        disabled={authCode?.length < 4}
        onClick={onConfirm}
        className={classNames(styles.code_button, styles.steps_button)}
        variant="contained"
        type="submit"
      >
        {confirmLoading ? <CircularProgress size={16} /> : 'אישור'}
      </Button>
    </>
  );
};
export default CodeStep;
