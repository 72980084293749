import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import logo from "../../../assets/images/bright-logo.png";
import {
  showPopupFunc,
  hidePopup,
  setInitialVisit,
} from "../../store/featuresPopup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

const FeaturesPopup = () => {
  const { isOpenMobileAuth } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const location = useLocation();
  const intervalId = useRef(null);
  const { showPopup, initialVisit } = useSelector(
    (state) => state.featuresPopup
  );

  useEffect(() => {
    const popupRoutes = [
      "/suppliers",
      "/supplier",
      "supplier/:id/:currntEvent",
      "supplier/:id",
    ];
    const isMatch = popupRoutes.some((pattern) =>
      matchPath(pattern, location.pathname)
    );
    if (isMatch && !isOpenMobileAuth) {
      const currentTimer = initialVisit ? 30000 : 300000;
      if (!showPopup) {
        intervalId.current = setTimeout(() => {
          dispatch(showPopupFunc());
          initialVisit && dispatch(setInitialVisit(false));
        }, currentTimer);
      }
    } else {
      dispatch(hidePopup());
      clearTimeout(intervalId.current);
    }
    return () => {
      clearTimeout(intervalId.current);
    };
  }, [location, dispatch, initialVisit, showPopup]);
  const handleClose = () => {
    dispatch(hidePopup());
  };
  const closeButton = (
    <Box
      component={"svg"}
      xmlns="http://www.w3.org/2000/svg"
      width="25.477px"
      height="25.47px"
      viewBox="0 0 25.477 25.47"
      onClick={handleClose}
      sx={{
        cursor: "pointer",
        position: "absolute",
        top: { xs: "-28px", md: "-34.75px" },
        right: 0,
        width: { xs: "14px", md: "21px" },
        path: {
          transition: "0.4s",
        },
        ":hover path": {
          fill: "black",
        },
      }}
    >
      <path
        id="Icon_ionic-ios-close"
        data-name="Icon ionic-ios-close"
        d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
        transform="translate(-11.285 -11.289)"
        fill="white"
      />
    </Box>
  );
  return (
    <Dialog
      open={showPopup}
      onClose={handleClose}
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.2)",
        },
        ".MuiPaper-root": {
          overflowY: "unset",
          margin: { xs: "19px", md: "32px" },
          top: { xs: "-14.57%", md: "unset" },
        },
      }}
    >
      {closeButton}
      <DialogContent
        sx={{
          p: { xs: "30px 42px 33px", md: "33px 72px 46px" },
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          boxShadow: "0px 4px 6px #00000029",
          position: "relative",
        }}
      >
        <Stack alignItems="center">
          <Box
            sx={{ maxWidth: { xs: "58px", md: "76px" } }}
            component={"img"}
            src={logo}
            alt="logo"
          />
          <Typography
            mt={{ xs: "28px", md: "30px" }}
            lineHeight={{ xs: "25px", md: "32px" }}
            sx={{ fontSize: { xs: "18px", md: "22px" } }}
            letterSpacing={{ xs: "unset", md: "0.29px" }}
            fontFamily={"Heebo-Bold"}
            textAlign={"center"}
          >
            סוגרים ספק דרכנו, ומקבלים ביימי על חשבוננו!
          </Typography>
        </Stack>
        <Button
          color="inherit"
          size="small"
          variant="contained"
          onClick={handleClose}
          sx={{
            mt: { xs: "21px", md: "32px" },
            height: { xs: "45px", md: "42px" },
            maxWidth: { xs: "85px", md: "145px" },
          }}
        >
          הבנתי!
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FeaturesPopup;
