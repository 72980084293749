import styles from './Footer.module.css';
import logo from '../../../assets/images/logoWithoutBackground.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { setIsOpenSupplierAuth } from '../../store/mainSlice';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';

function Footer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useAuth();
  let currentRole = auth?.roles ? auth.roles[0] : '';

  return (
    <>
      <footer className={styles.footerContainer}>
        <img alt="logo" src={logo} className={styles.logo} />
        <div className={styles.lists}>
          <div className={classNames(styles.flexColumn, styles.listBlock, styles.supplierPageLink)}>
            <div
              onClick={() => dispatch(setIsOpenSupplierAuth(true))}
              className={classNames(styles.pointer, styles.underlineLink, styles.titleSupplier)}
            >
              <h3>הצטרפות ספקים</h3>
            </div>
            <p>
              מלאו את היומן שלכם באירועים
              <br />
              אמיתיים ולא בפגישות מיותרות
            </p>
            <div
              className={classNames(
                styles.pointer,
                styles.underlineLink,
                styles.callToActionButton,
                styles.mobile_dissabled
              )}
            >
              <h3>דברו איתנו</h3>
            </div>
          </div>
          <div className={classNames(styles.flexColumn, styles.listBlock, styles.suppliersList)}>
            <div onClick={() => navigate('/suppliers')} className={classNames(styles.pointer)}>
              <h3>הספקים שלנו</h3>
            </div>

            <ul className={classNames(styles.flexColumn)}>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 15);
                  localStorage.setItem('nameCategory', 'קייטרינג');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                קייטרינג
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 16);
                  localStorage.setItem('nameCategory', 'צילום');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                צילום
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 17);
                  localStorage.setItem('nameCategory', 'איפור ושיער');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                איפור ושיער
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 22);
                  localStorage.setItem('nameCategory', 'רבנים ועורכי טקסים');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                עורכי טקסים
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 12);
                  localStorage.setItem('nameCategory', 'מוזיקה');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                DJ
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 13);
                  localStorage.setItem('nameCategory', 'אלכוהול');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                אלכוהול
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('idCategory', 2);
                  localStorage.setItem('nameCategory', 'שמלות כלה וערב');
                  navigate('/suppliers');
                }}
                className={classNames(styles.pointer)}
              >
                שמלות כלה
              </li>
              <li onClick={() => navigate('/suppliers')} className={classNames(styles.pointer)}>
                עוד ספקים
              </li>
            </ul>
          </div>
          <div className={classNames(styles.flexColumn, styles.listBlock, styles.navList)}>
            <h3
              onClick={() => navigate('/about-us')}
              className={classNames(styles.pointer, styles.mobile_dissabled)}
            >
              איוונטדו
            </h3>
            <ul className={classNames(styles.flexColumn)}>
              <li onClick={() => navigate('/about-us')} className={classNames(styles.pointer)}>
                אודות
              </li>
              <li onClick={() => navigate('/contacts')} className={classNames(styles.pointer)}>
                צרו קשר
              </li>
              <li onClick={() => navigate('/blog')} className={classNames(styles.pointer)}>
                בלוג
              </li>
              <li>
                <a href={`${window.location.origin}/sitemap.xml`}>מפת אתר</a>
              </li>
              <li>
                <div
                  className={classNames(styles.link)}
                  onClick={() => {
                    currentRole === 'SUPPLIER' || currentRole === 'LABEL'
                      ? navigate(`/terms/1`)
                      : navigate(`/terms/2`);
                  }}
                >
                  תקנון אתר
                </div>
              </li>
            </ul>
          </div>
          <div className={classNames(styles.flexColumn, styles.listBlock, styles.contacts)}>
            <div onClick={() => navigate('/contacts')} className={classNames(styles.pointer)}>
              <h3>צרו קשר</h3>
            </div>
            <ul className={styles.flexColumn}>
              <li>077-6707003</li>
              <li className={styles.link}>
                <a href="mailto:admin@event-do.com">admin@event-do.com</a>
              </li>
              <li className={styles.listItemWithIcons}>
                <a
                  className={classNames(styles.instagrammIcon, styles.link)}
                  href="https://www.instagram.com/event_do_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26.6"
                    height="26.6"
                    viewBox="0 0 26.6 26.6"
                  >
                    <g
                      id="Icon_feather-instagram"
                      data-name="Icon feather-instagram"
                      transform="translate(-2 -2)"
                    >
                      <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M9.15,3h12.3A6.15,6.15,0,0,1,27.6,9.15v12.3a6.15,6.15,0,0,1-6.15,6.15H9.15A6.15,6.15,0,0,1,3,21.45V9.15A6.15,6.15,0,0,1,9.15,3Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M21.852,16.133a4.92,4.92,0,1,1-4.145-4.145,4.92,4.92,0,0,1,4.145,4.145Z"
                        transform="translate(-1.632 -1.608)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M26.25,9.75h0"
                        transform="translate(-4.185 -1.215)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </a>
                <a
                  className={classNames(styles.facebookIcon, styles.link)}
                  href="https://www.facebook.com/eventdo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.5"
                    height="32"
                    viewBox="0 0 18.5 32"
                    className={styles.link}
                  >
                    <path
                      id="Icon_feather-facebook"
                      data-name="Icon feather-facebook"
                      d="M27,3H22.5A7.5,7.5,0,0,0,15,10.5V15H10.5v6H15V33h6V21h4.5L27,15H21V10.5A1.5,1.5,0,0,1,22.5,9H27Z"
                      transform="translate(-9.5 -2)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
