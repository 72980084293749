import React from 'react';
import styles from '../styleBasicForm.module.scss';
import { Typography } from '@mui/material';
import arrow from '../../../../../assets/images/homePage/form/arrow1.webp';
import ProgressBarForm from './ProgressBarForm';
import StepForm from './stepForm.js/StepForm';
import { DEFAULT_FORM_VALUES } from './stepForm.js/stepForm.config';
import { FormProvider, useForm } from 'react-hook-form';

const Form = () => {
  const defaultValues = DEFAULT_FORM_VALUES;

  const form = useForm({ defaultValues: defaultValues, mode: 'onChange' });
  return (
    <div className={styles.form_container}>
      <div className={styles.form_text}>
        <Typography className={styles.form_title}>איוונט-דו להפיק בקליק</Typography>
        <Typography className={styles.form_description}>
          חפשו את הצוות המנצח שלכם מבין מאות ספקים מקצועיים עם חוות דעת, מחירים, זמינות ועוד.
        </Typography>
        <img className={styles.form_arrow} src={arrow} alt="arrow" />
      </div>
      <FormProvider {...form}>
        <form>
          <ProgressBarForm />
          <StepForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
