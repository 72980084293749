import styles from "./HeaderMobile.module.scss";
import logo from "../../../assets/images/logoMobile.png";
import close from "../../../assets/images/for-pages/icons/closeDialog.png";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { savePath } from "../../store/returnToUrlSlice";
import notificationImg from "../../../assets/images/header/notification_mobile.png";
import threeLine from "../../../assets/images/header/threeLine.png";
import arrowDown from "../../../assets/images/arrowDown.png";
import {
  setIsOpenUserAuth,
  setIsOpenSupplierAuth,
  setCurrentEventForChecklist,
  setCurrentEvent,
} from "../../store/mainSlice";
import { setCurrentEventChecklist } from "../../store/checkListSlice";
//import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const activeClass = ({ isActive }) => (isActive ? "chosenLink" : "");

function HeaderMobile() {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  let location = useLocation();
  const { auth, logOut } = useAuth();
  // let currentRole = auth?.roles ? auth.roles[0] : "";
  // const axiosPrivate = useAxiosPrivate();

  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  //const [newNotification, setnewNotification] = useState(false);

  const goToUserLogin = () => {
    dispatch(savePath(pathname));
    setIsShowMenu(false);
    dispatch(setIsOpenUserAuth(true));
  };

  const clear = () => {
    dispatch(
      setCurrentEventChecklist({
        currentEvent: { checkListId: 0, eventTypeName: "" },
      })
    );
    dispatch(
      setCurrentEventForChecklist({
        currentEventForChecklist: { id: 0, name: "" },
      })
    );
    setCurrentEvent({});
  };
  // const goToPersonalArea = () => {
  //   if (currentRole === "USER") {
  //     return "/personal/chats";
  //     // navigate(`/personal/chats`);
  //   } else if (currentRole === "SUPPLIER" || currentRole === "LABEL") {
  //     return "/personal-supplier/chats";
  //     // navigate(`/personal-supplier/chats`);
  //   } else {
  //     return "/personal-guest/";
  //     // navigate(`/personal-guest/`);
  //   }
  // };

  useEffect(() => {
    if (isShowMenu) {
      document.documentElement.style.overflow = "hidden";
    } else document.documentElement.style.overflow = "auto";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMenu]);

  // useEffect(() => {
  //   let notificationId1;
  //   if(auth?.user){
  //   const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/check/messages`);
  //     result.then(res=>{
  //       setnewNotification(res.data);
  //     })
  //   notificationId1 = setInterval(() => {
  //   const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/check/messages`);
  //     result.then(res=>{
  //       setnewNotification(res.data);
  //     })
  //   }, 60000);
  //   }
  //   return () => {
  //     clearInterval(notificationId1);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {/* <div className={styles.headerContainerMobile}>

      </div> */}

      <header className={styles.headerMobileContainer}>
        <div>
          <img
            onClick={() => {
              setIsShowMenu(!isShowMenu);
            }}
            className={styles.menuImg}
            src={threeLine}
            alt="menuImg"
          />
          {/* <svg
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsShowMenu(!isShowMenu);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="14"
            viewBox="0 0 27 14"
          >
            <g id="Group_99" data-name="Group 99" transform="translate(1 1)">
              <line
                id="Line_18"
                data-name="Line 18"
                x1="25"
                fill="none"
                stroke="#242323"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <line
                id="Line_19"
                data-name="Line 19"
                x1="25"
                transform="translate(0 6)"
                fill="none"
                stroke="#242323"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <line
                id="Line_20"
                data-name="Line 20"
                x1="25"
                transform="translate(0 12)"
                fill="none"
                stroke="#242323"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </svg> */}
          <img className={styles.notificationImg} src={notificationImg} alt="notificationImg" />
        </div>

        <img
          onClick={() => {
            navigate("/");
          }}
          className={styles.logoImg}
          alt="logoImage"
          src={logo}
        />

        {isShowMenu && (
          <div className={styles.menuBack}>
            <div className={styles.menuContainer}>
              <div className={styles.logoContainer}>
                <img alt="logoImage" src={logo} />
              </div>
              <div className={styles.closeContainer}>
                <img
                  onClick={() => {
                    setIsShowMenu(!isShowMenu);
                  }}
                  alt="logoImage"
                  src={close}
                />
              </div>
              <div>
                <ul className={styles.headerListMenu}>
                  <li className={styles.firstLink}>
                    <NavLink to="/suppliers" onClick={() => setIsShowMenu(false)} className={activeClass}>
                      הספקים שלנו
                    </NavLink>
                  </li>
                  <li className={styles.secondLink}>
                    <NavLink to="/blog" onClick={() => setIsShowMenu(false)} className={activeClass}>
                      הבלוג שלנו
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contacts" onClick={() => setIsShowMenu(false)} className={activeClass}>
                      צרו קשר
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className={activeClass} to="/about-us" onClick={() => setIsShowMenu(false)}>
                      מי אנחנו
                    </NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setIsOpenDropDown(!isOpenDropDown);
                    }}
                    className={styles.areaButton}
                  >
                    <div style={{ cursor: "pointer" }} className={styles.label}>
                      {"אזור אישי"}
                    </div>
                    {/* {newNotification && (
                    <img
                      src={notification}
                      alt="notification"
                      className={styles.notificationImg}
                    />
                  )} */}
                    <span className={styles.arrow}>
                      <img
                        style={{
                          transform: isOpenDropDown ? "rotate(180deg)" : "",
                        }}
                        alt="arrow"
                        src={arrowDown}
                      />
                    </span>
                  </li>

                  {isOpenDropDown && (
                    <>
                      {auth?.roles?.[0] === "SUPPLIER" || auth?.roles?.[0] === "LABEL" ? (
                        <>
                          <li>
                            <NavLink to="/personal-supplier/change-supplier" onClick={() => setIsShowMenu(false)}>
                              <div
                                className={
                                  location.pathname === "/personal-supplier/change-supplier" && "chosenLink"
                                }
                              >
                                {"פרטים אישיים"}
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/personal-supplier/change-supplier-business"
                              onClick={() => setIsShowMenu(false)}
                            >
                              <div
                                className={
                                  location.pathname === "/personal-supplier/change-supplier-business" &&
                                  "chosenLink"
                                }
                              >
                                {"פרטי העסק"}
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/personal-supplier/change-label-business"
                              onClick={() => setIsShowMenu(false)}
                            >
                              <div
                                className={
                                  location.pathname === "/personal-supplier/change-label-business" && "chosenLink"
                                }
                              >
                                {"שליטה על ספק"}
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/personal-supplier/chats" onClick={() => setIsShowMenu(false)}>
                              <div className={location.pathname === "/personal-supplier/chats" && "chosenLink"}>
                                {"מערכת פניות"}
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/personal-supplier/calendar" onClick={() => setIsShowMenu(false)}>
                              <div className={location.pathname === "/personal-supplier/calendar" && "chosenLink"}>
                                {"עדכון יומן"}
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/personal-supplier/documents" onClick={() => setIsShowMenu(false)}>
                              <div
                                className={location.pathname === "/personal-supplier/documents" && "chosenLink"}
                              >
                                {"מסמכים"}
                              </div>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <NavLink
                              to={
                                auth.roles?.[0] === "USER"
                                  ? "/personal/change-user"
                                  : "/personal-guest/change-user"
                              }
                              // className={activeClass}
                              onClick={() => setIsShowMenu(false)}
                            >
                              <div
                                className={
                                  (location.pathname === "/personal/change-user" ||
                                    location.pathname === "/personal-guest/change-user") &&
                                  "chosenLink"
                                }
                              >
                                פרטים אישיים
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              to={auth.roles?.[0] === "USER" ? "/personal/checklist" : "/personal-guest/checklist"}
                              // className={activeClass}
                              onClick={() => setIsShowMenu(false)}
                            >
                              <div
                                className={
                                  (location.pathname === "/personal/checklist" ||
                                    location.pathname === "/personal-guest/checklist") &&
                                  "chosenLink"
                                }
                              >
                                הצ'קליסט שלי
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              onClick={() => {
                                if (!auth?.user) {
                                  dispatch(setIsOpenUserAuth(true));
                                  dispatch(savePath(pathname));
                                }
                                setIsShowMenu(false);
                              }}
                              to={auth?.user ? "/personal/favorites" : ""}
                            >
                              <div className={location.pathname === "/personal/favorites" && "chosenLink"}>
                                ספקים שאהבתי
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              to={auth.roles?.[0] === "USER" ? "/personal/chats" : ""}
                              // className={activeClass}
                              onClick={() => {
                                if (!auth?.user) {
                                  dispatch(setIsOpenUserAuth(true));
                                  dispatch(savePath(pathname));
                                }
                                setIsShowMenu(false);
                              }}
                            >
                              <div className={location.pathname === "/personal/chats" && "chosenLink"}>צ'אט</div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              to={auth.roles?.[0] === "USER" ? "/personal/add-event" : "/personal-guest/add-event"}
                              // className={activeClass}
                              onClick={() => setIsShowMenu(false)}
                            >
                              <div
                                className={
                                  (location.pathname === "/personal/add-event" ||
                                    location.pathname === "/personal-guest/add-event") &&
                                  "chosenLink"
                                }
                              >
                                הוספת אירוע
                              </div>
                            </NavLink>
                          </li>

                          {auth?.roles?.[0] === "USER" && (
                            <li>
                              <NavLink to="/personal/documents" onClick={() => setIsShowMenu(false)}>
                                <div className={location.pathname === "/personal/documents" && "chosenLink"}>
                                  {"מסמכים"}
                                </div>
                              </NavLink>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <div className={styles.underline}></div>

              {auth?.user ? (
                <>
                  <ul className={styles.headerListLogin}>
                    <li style={{ fontFamily: "Heebo-Bold" }} className={styles.linkToCustomerEnry1}>
                      {"שלום, "}
                      {JSON.parse(auth?.user)?.name}
                    </li>
                  </ul>

                  <div
                    className={styles.logout}
                    onClick={() => {
                      logOut();
                      clear();
                      //setnewNotification(false);
                      navigate("/");
                    }}
                  >
                    {"התנתק"}
                  </div>
                </>
              ) : (
                <ul className={styles.headerListLogin}>
                  <li style={{ cursor: "pointer" }} className={styles.linkToCustomerEnry1} onClick={goToUserLogin}>
                    כניסת חוגג
                  </li>
                  <li className={styles.linkToCustomerEnry2}>
                    <li
                      onClick={() => {
                        dispatch(setIsOpenSupplierAuth(true));
                        setIsShowMenu(false);
                      }}
                      className={activeClass}
                    >
                      כניסת ספק
                    </li>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
      </header>
    </>
  );
}

export default HeaderMobile;
