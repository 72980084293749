import { Typography } from '@mui/material';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styleSuppliers.module.scss';
import arrow from '../../../../assets/images/homePage/form/arrow1.webp';
import { useEffect, useLayoutEffect } from 'react';
import { fetchAllEvetns, setCurrentEvent, setCurrentSupplierEvent } from '../../../store/mainSlice';
import { setSelectedEventTypeId } from '../../../store/suppliersSlice';
import { useFormContext } from 'react-hook-form';

const TypeEvent = () => {
  const { allEvents, currentEvent } = useSelector((state) => state.main);
  const { selectedEventTypeId } = useSelector((state) => state.suppliers);
  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!currentEvent && currentEvent.id !== 0 && selectedEventTypeId !== currentEvent.id){
      dispatch(setSelectedEventTypeId(currentEvent.id));
    }
  }, []);
  useEffect(() => {
    if (!!allEvents || allEvents.length === 0) {
      dispatch(fetchAllEvetns());
    }
  }, []);
  const onChangeForStore = (e, data) => {
    setValue('category', '');
    setValue('categoryFiltersId', []);
    const option = { id: data.props.value, name: data.props.children };
    dispatch(setSelectedEventTypeId(option.id));
    dispatch(setCurrentSupplierEvent({ currentSupplierEvent: option }));
    dispatch(setCurrentEvent({ currentEvent: option }));
  };
  return (
    <section id="suppliers-type-event" className={styles.type_event_container}>
      <Typography className={styles.label}>מה חוגגגים?</Typography>
      <CustomSelect
        onChange={onChangeForStore}
        sxSelect={{ maxWidth: '228px', height: '42px' }}
        placeholder="בחר אירוע מהרשימה"
        controlValue={selectedEventTypeId}
        options={allEvents}
      />
      <img className={styles.form_arrow} src={arrow} alt="arrow" />
    </section>
  );
};
export default TypeEvent;
