import { Box, FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CustomSelectShell from './CustomSelectShell';
const CustomSelect = (props) => {
  const {
    name,
    rules,
    label,
    placeholder,
    options,
    onChange,
    sxSelect,
    sxHelpText,
    sxPlaceHolder,
    sxLabel,
    multiple,
    disabled,
    controlValue,
  } = props;
  const { formState } = useFormContext();
  const isExistsErors = formState.errors[name];

  return (
    <>
      {label && (
        <Typography sx={{ fontSize: '16px', marginBottom: '10px', ...sxLabel }}>{label}</Typography>
      )}
      <CustomSelectShell name={name} rules={rules}>
        {(field) => (
          <>
            <Select
              disabled={disabled}
              multiple={multiple && multiple}
              sx={{
                height: '100%',
                ...(isExistsErors && {
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '1px solid red',
                  },
                }),
                ...sxSelect,
              }}
              value={name ? field.value : controlValue}
              renderValue={(value) => {
                const currentValue = name
                  ? value
                  : controlValue
                  ? controlValue
                  : !!multiple
                  ? []
                  : '';
                return (!!multiple ? currentValue.length > 0 : !!currentValue) ? (
                  multiple ? (
                    currentValue
                      .map((oneItem) => {
                        return options.find((item) => item.id === oneItem)?.name;
                      })
                      .join(',')
                  ) : (
                    options.find((item) => item.id === currentValue)?.name
                  )
                ) : (
                  <Box
                    sx={{
                      opacity: '0.3',
                      fontSize: { xs: '14px', sm: '16px' },
                      lineHeight: { xs: '21px', sm: '24px' },
                      ...sxPlaceHolder,
                    }}
                  >
                    {placeholder ? placeholder : ''}
                  </Box>
                );
              }}
              onChange={(e, date) => {
                const newValue = date.props.value;
                if (multiple) {
                  onChange && onChange(e, date);
                  if (name) {
                    const isExists = field.value.find((item) => item === newValue);
                    field.onChange(
                      isExists
                        ? field.value.filter((oneItem) => oneItem !== newValue)
                        : [...field.value, newValue]
                    );
                  }
                } else {
                  onChange && onChange(e, date);
                  if (name) field.onChange(newValue);
                }
              }}
              displayEmpty
            >
              {options.map((oneItem) => (
                <MenuItem key={oneItem.id} value={oneItem.id}>
                  {oneItem.name}
                </MenuItem>
              ))}
            </Select>
            {isExistsErors && (
              <FormHelperText
                sx={{ margin: '3px 14px 0px', fontSize: { xs: '10px', sm: '12px' }, ...sxHelpText }}
                error
              >
                {formState.errors[name].message}
              </FormHelperText>
            )}
          </>
        )}
      </CustomSelectShell>
    </>
  );
};
export default CustomSelect;
