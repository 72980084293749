import Card from './Card';
import { useCardsLogic } from './useCardsLogic';
import styles from './styleCards.module.scss';
import { Typography } from '@mui/material';
const Cards = () => {
  const { items, refFlag, addToFavourite, totalSize, currentCategoryId } = useCardsLogic();
  return (
    <section id="suppliers-cards" className={styles.cards_container} style={{marginTop:currentCategoryId !== 1 ? "80px" :"0px"}}>
      {currentCategoryId === 1 && (
        <p className={styles.size_venues}>נמצאו  <span>{totalSize}</span> מקומות עבור האירוע שלכם.
            לחצו על פנייה לספק עבור קבלת פרטים נוספים     
        </p>
      )}
      <div className={styles.fourth}>
        {items.map((oneItem) => (
          <div
            key={`card-${oneItem.id}-categoryId-${oneItem.categoryId}-categoryName-${oneItem.categoryName}`}
            className={styles.fourthSlideItem}
          >
            <Card supplier={oneItem} addToFavourite={addToFavourite} />
          </div>
        ))}
      </div>

      <div ref={refFlag} />
    </section>
  );
};
export default Cards;
